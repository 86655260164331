import React from 'react';

const ProfilePhotoCmp = () => (
  <div className='flex justify-center -mt-5 md:justify-end md:w-2/5 sm:w-full'>
    <div className='bg-dots'>
      <div className='z-10 max-w-md mt-6 ml-4 mr-4 rounded-full shadow-2xl md:mr-0'>
        <img
          alt='card img'
          className='rounded'
          src={`/images/adam/langino.jpg`}
        />
      </div>
    </div>
  </div>
);

export default ProfilePhotoCmp;
