import React from 'react';

const QuoteCmp = () => (
  <div className='w-full p-5 md:p-10 lg:text-2xl md:text-base md:w-3/5'>
    <blockquote className='base-text-color quote'>
      Too often, the law bends in favor of powerful corporations with the wealth
      and connections to avoid accountability. Their recklessness, greed, and
      indifference leave families heartbroken and futures shattered. Through my
      work, I strive to help people find recourse against these companies, and
      in doing so, to balance the scales of justice.
    </blockquote>
    <cite className='float-right mt-4 text-sm base-text-color'>
      - Adam J. Langino, Founder
    </cite>
  </div>
);

export default QuoteCmp;
