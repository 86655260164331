import React from 'react';
import { Link } from 'gatsby';

const MiniBioCmp = () => (
  <>
    <div className='mt-12 text-base text-center lg:text-xl md:w-1/1 base-text-color'>
      Adam J. Langino is an accomplished attorney, well-respected within the
      legal community on the local and national level. He handles catastrophic
      injury and wrongful death cases in North Carolina and Florida, and
      co-counsels throughout the country. He has the knowledge, experience, and
      courage to stand up to insurance companies and large corporations on
      behalf of his clients, providing a secure future for you and your family.
    </div>
    <div className='h-16 my-5 mt-10 text-center'>
      <Link
        to='/about'
        className='w-48 px-4 py-2 text-lg font-medium text-center text-white rounded shadow-md cursor-pointer hover:no-underline bg-primary hover:bg-secondary'
      >
        Learn more
      </Link>
    </div>
  </>
);

export default MiniBioCmp;
