import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import ResultCmp from '../components/ResultCmp';
import QuoteCmp from '../components/QuoteCmp';
import ProfilePhotoCmp from '../components/ProfilePhotoCmp';
import MiniBioCmp from '../components/MiniBioCmp';
import { resultsSplashData } from '../data/resultsData';

const IndexPage = () => (
  <Layout>
    <Seo title='Home' />

    <div className='max-w-6xl p-4 mt-4 sm:m-auto sm:p-12'>
      {/* Intro Section */}
      <div className='flex flex-col pb-10 border-b-2'>
        <div className='flex flex-col justify-center md:flex-row'>
          <QuoteCmp />
          <ProfilePhotoCmp />
        </div>
        <MiniBioCmp />
      </div>

      {/* Results Section */}
      <div className='pt-2 pb-6 mx-auto mt-2 border-b-4 sm:block'>
        <h1 className='py-4 text-center font-slim text-primary'>Results</h1>
        <div className='grid justify-center grid-cols-1 gap-4 px-6 my-4 md:grid-cols-3 sm:w-auto'>
          {resultsSplashData.map((result, index) => (
            <ResultCmp
              key={index}
              id={result.id}
              type={result.type}
              subtype={result.subtype}
              amount={result.amount}
              description={result.description}
            />
          ))}
        </div>
        <div className='h-16 my-5 mt-10 text-center'>
          <Link
            to='/results'
            className='w-48 px-4 py-2 text-lg font-medium text-center text-white rounded shadow-md cursor-pointer hover:no-underline bg-primary hover:bg-secondary'
          >
            See more cases
          </Link>
        </div>
      </div>

      {/* What People Are Saying Section */}
      <div className='py-16 mt-32 bg-indigo-100 rounded-md'>
        <div className='container px-4 mx-auto'>
          <h3 className='mb-3 text-3xl font-extrabold leading-tight text-center text-primary'>
            What People Are Saying About Langino Law
          </h3>

          <hr className='w-64 h-1 p-0 m-0 mx-auto mb-10 opacity-25 gradient' />
          <div className='flex flex-wrap justify-center -mx-4 dark-text'>
            {/*  */}
            <div className='w-full px-4 mt-6 lg:w-1/3 lg:mt-0'>
              <div className='flex -mx-4'>
                <div className='px-4 '>
                  <div className='p-4 bg-white rounded-lg shadow'>
                    "I have known Adam for 15 years. We both served the
                    community as Assistant Public Defenders, and later re-united
                    at a national Plaintiffs firm. Our relationship began as
                    professionals, and developed into a friendship. This
                    transition allowed me to see Adam as not only a diligent,
                    focused, and determined lawyer, but a caring individual who
                    works extremely hard at achieving justice for his clients.
                    Even though Adam has all of the skills in his tool box to
                    serve his clients well, he is continuously striving to
                    sharpen these skills, which only inure to the benefit of his
                    clients."
                  </div>
                  <div className='ml-4 -mt-1'>
                    <svg
                      height='20'
                      width='20'
                      viewBox='0, 0, 20, 20'
                      className='text-white'
                    >
                      <polygon
                        points='0,0 20,0 10,20'
                        className='triangle'
                        fill='white'
                      ></polygon>
                    </svg>
                  </div>
                  <div className='flex mt-2'>
                    <div className='flex-shrink-0 w-16 text-center'>
                      <img
                        className='rounded-full'
                        src={`/images/testimonials/nicholas.jpg`}
                        alt=''
                      ></img>
                    </div>

                    <p className='mt-5 ml-2 font-bold'>
                      Nicholas C. Johnson
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
            <div className='w-full px-4 mt-6 lg:w-1/3 lg:mt-0'>
              <div className='flex -mx-4'>
                <div className='px-4 '>
                  <div className='p-4 bg-white rounded-lg shadow'>
                    "Adam is a smart, compassionate, and skilled trial attorney.
                    His razor-sharp intellect, charisma, and knowledge of the
                    law is unrivaled in any courtroom he walks into, regardless
                    of the stakes. I have partnered with Adam in many trials,
                    and I know first-hand how his command of the courtroom wins
                    cases. I recommend him without any reservation."
                  </div>
                  <div className='ml-4 -mt-1'>
                    <svg
                      height='20'
                      width='20'
                      viewBox='0, 0, 20, 20'
                      className='text-white'
                    >
                      <polygon
                        points='0,0 20,0 10,20'
                        className='triangle'
                        fill='white'
                      ></polygon>
                    </svg>
                  </div>
                  <div className='flex mt-2'>
                    <div className='flex-shrink-0 w-16 text-center'>
                      <img
                        className='rounded-full'
                        src={`/images/testimonials/sabarish.png`}
                        alt=''
                      ></img>
                    </div>

                    <p className='mt-5 ml-2 font-bold'>
                      Sabarish Neelakanta
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>

            {/*  */}
            <div className='w-full px-4 mt-6 lg:w-1/3 lg:mt-0'>
              <div className='flex -mx-4'>
                <div className='px-4 '>
                  <div className='p-4 bg-white rounded-lg shadow'>
                    "I've known Adam for many years, starting from his time
                    working as an Assistant Public Defender in Florida. There
                    are two characteristics about Adam I personally like: first
                    is his sense of pragmatism. He is a problem-solver and that
                    manifests itself whether we are playing chess or he is
                    giving me career advice. The second and, maybe, most
                    important characteristic about Adam I like is his deep sense
                    of humanism. He truly cares about people. He loves to see
                    those around him succeed. He is also a helper - always ready
                    and willing to land a helping hand whenever he is called
                    upon."
                  </div>
                  <div className='ml-4 -mt-1'>
                    <svg
                      height='20'
                      width='20'
                      viewBox='0, 0, 20, 20'
                      className='text-white'
                    >
                      <polygon
                        points='0,0 20,0 10,20'
                        className='triangle'
                        fill='white'
                      ></polygon>
                    </svg>
                  </div>
                  <div className='flex mt-2'>
                    <div className='flex-shrink-0 w-16 text-center'>
                      <img
                        className='rounded-full'
                        src={`/images/testimonials/miche.jpg`}
                        alt=''
                      ></img>
                    </div>

                    <p className='mt-5 ml-2 font-bold'>
                      Miche Jean
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
